import React, { ReactElement, memo, useState } from 'react'
import './Sustainability.scss'
import { Link, graphql } from 'gatsby'
import loadable from '@loadable/component'
import Seo from '../../components/Seo/Seo'

const Layout = loadable(() => import('../../components/Layout/Layout'))

type ArticleTypes = {
  pillar: string
  title: string | undefined
  subtitle?: string
  slug?: string
}

type ContentTypes = {
  category: string
  title: string
  shortDescription: string
  subtitle: string
  id: string
  slug?: string
}

type SustainabilityTypes = {
  data: {
    allContent: { nodes: ContentTypes[] }
  }
}

const Sustainability = ({
  data: {
    allContent: { nodes: pillarList },
  },
}: SustainabilityTypes): ReactElement => {
  const connectivity = useState(
    pillarList.find((pillar) => pillar?.subtitle === `ca`),
  )

  const rightSized = useState(
    pillarList.find((pillar) => pillar?.subtitle === `r`),
  )
  const ecoEfficiency = useState(
    pillarList.find((pillar) => pillar?.subtitle === `e`),
  )
  const structural = useState(
    pillarList.find((pillar) => pillar?.subtitle === `s`),
  )

  const SustainabilityData: ArticleTypes[] = [
    {
      pillar: 'Connectivity and Accessibility',
      title: connectivity[0]?.title,
      subtitle: connectivity[0]?.subtitle,
      slug: connectivity[0]?.slug,
    },
    {
      pillar: 'Right-Sized Living Spaces',
      title: rightSized[0]?.title,
      subtitle: rightSized[0]?.subtitle,
      slug: rightSized[0]?.slug,
    },
    {
      pillar: 'Eco-Efficiency',
      title: ecoEfficiency[0]?.title,
      subtitle: ecoEfficiency[0]?.subtitle,
      slug: ecoEfficiency[0]?.slug,
    },
    {
      pillar: 'Structural Stability',
      title: structural[0]?.title,
      subtitle: structural[0]?.subtitle,
      slug: structural[0]?.slug,
    },
  ]

  return (
    <Layout>
      <Seo
        title="Amaia Cares Sustainability | Amaia Land "
        ogMetaData={{
          description: 'Amaia Sustainability',
        }}
      />

      <div className="sustainability">
        <div className="sustainability-pillars mobile">
          <div className="content">
            {SustainabilityData.map((pillar) => {
              return pillar.title ? (
                <Link
                  key={pillar.title}
                  to={`/sustainability/pillar/${pillar.slug}`}
                  className="link"
                >
                  <div className={`info ${pillar.subtitle}`}>
                    <div className={`pillar-container ${pillar.subtitle}`}>
                      <h3>{pillar.subtitle?.toLocaleUpperCase() ?? ''}</h3>
                    </div>
                    <button
                      type="button"
                      className={`info-container ${pillar.subtitle}-font`}
                    >
                      <h3>{pillar.pillar}</h3>
                    </button>
                  </div>
                </Link>
              ) : (
                ''
              )
            })}
          </div>
        </div>
        <div className="sustainability-info">
          <h4 className="title">Sustainability</h4>
          <p className="content">
            In our mission to serve aspiring Filipino homeowners with affordable
            yet quality homes, we commit ourselves in building sustainable
            communities that support comfortable lives today and in many years
            to come. Amaia CARES is a sustainability campaign which stands for
            Connectivity and Accessibility (CA), Right-sized Living Spaces (R),
            Eco-efficiency (E) and Structural Stability (S). These four make up
            Amaia’s quadruple sustainability platform.
          </p>
          <p className="content">
            We believe that sustainable living is something that should be
            embraced by everyone, even those in entry-level homes such as ours.
          </p>

          <p className="content">
            We hope to encourage our residents and partners to work hand in hand
            with us in building and preserving sustainable communities that can
            last for generations to come.
          </p>
          <p className="content">
            With this campaign, Amaia hopes to help consumers make informed
            decisions in selecting the right property investment.
          </p>
        </div>
        <div className="sustainability-image-container">
          <iframe
            title="sustainability-video"
            src="https://www.youtube.com/embed/XKoavCNyKfE"
          />
        </div>

        <div className="sustainability-pillars">
          <div className="content">
            {SustainabilityData.map((pillar) => {
              return pillar.title ? (
                <Link
                  key={pillar.title}
                  to={`/sustainability/pillar/${pillar.slug}`}
                  className="link"
                >
                  <div className={`info ${pillar.subtitle}`}>
                    <div className={`pillar-container ${pillar.subtitle}`}>
                      <h3>{pillar.subtitle?.toLocaleUpperCase()}</h3>
                    </div>
                    <button
                      type="button"
                      className={`info-container ${pillar.subtitle}-font`}
                    >
                      <h3>{pillar.pillar}</h3>
                    </button>
                  </div>
                </Link>
              ) : (
                ''
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default memo(Sustainability)

export const pageQuery = graphql`
  query {
    allContent(filter: { category: { eq: "sustainability" } }) {
      nodes {
        ...SustainabilityFields
      }
    }
  }
`
